import FooterDivider from "../../components/FooterDivider";
import { ChatIcon, ScrollTop } from "../home/HomeElements";
import { ContactContainer, ContactDiv, ContactIcon, ContactOfficeDiv, ContactUsCondition, FieldGroup, FieldLabel, FieldRow, FormDesktopContainer, FormMobileContainer, HrContact, LabelText, LeaveMessage, MessageForm, MessageOfficeForm, MessageOfficeRow, MessageRow, MessageTitle, OfficeDiv, OfficeText, OfficeTitle, HrefLink, PotechMail, SubmitBtn, TextAreaStyle, TextHeader, OfficeTextWithLogo, ContactLogo } from "./ContactUsElement";

import messageIcon from '../../assets/logos/messageIcon.png'
import messageLoactionIcon from '../../assets/logos/contactUsMessageIcon.png'
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import { Checkbox, FormControlLabel, Input, TextareaAutosize } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { AdvisoryDivLineItemsMobile } from "../advisory/AdvisoryElement";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { resetTargetSection } from "../../redux/scrollSlice";
import { useDispatch, useSelector } from "react-redux";
import locationSvg from '../../assets/logos/location.svg'
import phoneSvg from '../../assets/logos/phone.svg'
import mailSvg from '../../assets/logos/mail.svg'
import CaptchaDialog from "../../common/captchaDialoge";
import ThanksDialog from "../../common/thanksDialog";
import agent from "../../utils";
import { Helmet } from "react-helmet";

const ContactUs = ({ isScrolled, setReshowDialog }) => {

    const top = useRef(null);
    useEffect(() => {
        top.current.scrollIntoView({ behavior: 'smooth' });
    }, [])

    const [job, setJob] = useState(null);
    const [fName, setFName] = useState(null);
    const [lName, setLName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [captchaKey, setCaptchaKey] = useState(null);

    const isEmpty = (value) => {
        if (value === null || value === '')
            return true;
        return false;
    }
    const clearForm = () => {
        setEmail(null);
        setPhone(null);
        setFName(null);
        setLName(null);
        setJob(null);
        setMessage(null);

        let form = document.getElementById("contact-form");
        var fromFields = form.querySelectorAll("input, textarea");
        fromFields.forEach((field) => {
            field.value = "";
        })
    }

    const signUpContactMessage = () => {
        setLoading(true);
        agent.Contact.postMessage(
            {
                "email": email,
                "message": message,
                "jobTitle": job,
                "firstName": fName,
                "lastName": lName,
                "phoneNumber": phone,
                "g-recaptcha-response": captchaKey
            }
        )
            .then(() => {
                clearForm();
                setThanksOpen(true);
            })
            .catch((err) => {
                if(err?.response?.status === 400) {
                    toast.error("Bad Request");
                }
                if (err?.response?.status === 500) {
                    toast.error("Internal Server Error");
                }
            })
            .finally(() => {
                setLoading(false);
                setCaptchaOpen(false);
            })
    }
    const handleSubmit = () => {
        // if (isChecked) {
        //     toast.success("Your form has been successfully submitted.")
        // } else {
        //     toast.error("You should agree to the terms of service and privacy policy.");
        // }
        if (localStorage.getItem('cookie') !== '1')
            setReshowDialog(true);
        else {
            setCaptchaOpen(true);
        }
    }
    const dispatch = useDispatch();

    const [captchaOpen, setCaptchaOpen] = useState(false);
    const [thanksOpen, setThanksOpen] = useState(false);
    const Label = <LabelText>I agree to the <a href="">terms of service</a> and <a href="">privacy policy</a>.</LabelText>
    const targetSection = useSelector((state) => state.scroll.targetSection);

    useEffect(() => {
        let sectionRef;

        switch (targetSection) {
            case 'contactUsTop':
                sectionRef = top;
                break;
            default:
                sectionRef = null;
                break;
        }

        if (sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            dispatch(resetTargetSection());
        }
    }, [targetSection, dispatch]);
    const TextInputStyle = { marginTop: '5vh', width: '100%' };
    const modalMessage = <>
        You Will Hear Back From Us Within a Few Days.<br />
        For Now, Visit and Follow Us on Social Media!
    </>
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var phoneRegex = /^(?:\+\d{1,3}\s?)?\(?\d{1,4}\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}$/;
    const isEmailValid = (!isEmpty(email) && emailRegex.test(email));
    const isPhoneValid = (!isEmpty(phone) && phoneRegex.test(phone));

    var tooltipButtonMessage = "";

    if (!isEmailValid) {
        tooltipButtonMessage += " Email invalid "
    }
    if (!isPhoneValid) {
        tooltipButtonMessage += " Phone invalid "
    }
    const isValid = !isEmpty(job) && !isEmpty(fName) && !isEmpty(lName) && isPhoneValid && isEmailValid && !isEmpty(message);
    return <>
        <Helmet>
            <title>Contact POTECH: Get in touch with our global offices</title>
            <meta name="description" content="Have a question, need help or want to share with us something? Contact POTECH today!" />
        </Helmet>
        <ContactContainer ref={top}>
            <CaptchaDialog
                loading={loading} 
                open={captchaOpen} 
                setOpen={setCaptchaOpen} 
                submitHandler={signUpContactMessage} 
                btnText={'SUBMIT'} 
                setCaptchaKey={setCaptchaKey} 
            />
            <ThanksDialog open={thanksOpen} setOpen={setThanksOpen} title={'Thank You For Your Message!'} message={modalMessage} />
            <TextHeader>
                Contact Us
            </TextHeader>
            <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
            <ChatIcon src={chatIcon} />
            <ContactDiv>
                <ContactIcon src={messageIcon} />
                <MessageTitle>
                    LEAVE US A MESSAGE
                </MessageTitle>
                <MessageForm>
                    <LeaveMessage>
                        Leave us a message and we’ll get back in touch with you.
                    </LeaveMessage>
                    <MessageRow id="contact-form">
                        <FieldGroup height={'50vh'} marginRight={'2vw'}>
                            <FormDesktopContainer>
                                <FieldRow>
                                    <FieldLabel>Job Title*</FieldLabel>
                                    <Input
                                        value={job}
                                        style={{ flex: '1 1 auto', marginTop: '2vh' }}
                                        onChange={event => setJob(event.target.value)}
                                        sx={{
                                            '& label.Mui-focused': {
                                                color: 'black',
                                            },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#002F87',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#002F87',
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                width: '100%',
                                                fontSize: '1vw'
                                            }
                                        }} />
                                </FieldRow>
                                <FieldRow>
                                    <FieldLabel>First Name*</FieldLabel>
                                    <Input
                                        value={fName}
                                        onChange={event => setFName(event.target.value)}
                                        style={{ flex: '1 1 auto', marginTop: '2vh' }}
                                        sx={{
                                            '& label.Mui-focused': {
                                                color: 'black',
                                            },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#002F87',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#002F87',
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                width: '100%',
                                                fontSize: '1vw'
                                            }
                                        }} />
                                </FieldRow>
                                <FieldRow>
                                    <FieldLabel>Last Name*</FieldLabel>
                                    <Input
                                        value={lName}
                                        onChange={event => setLName(event.target.value)}
                                        style={{ flex: '1 1 auto', marginTop: '2vh' }}
                                        sx={{
                                            '& label.Mui-focused': {
                                                color: 'black',
                                            },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#002F87',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#002F87',
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                width: '100%',
                                                fontSize: '1vw'
                                            }
                                        }} />
                                </FieldRow>
                                <FieldRow>
                                    <FieldLabel>Email*</FieldLabel>
                                    <Input
                                        value={email}
                                        onChange={event => setEmail(event.target.value)}
                                        style={{ flex: '1 1 auto', marginTop: '2vh' }}
                                        sx={{
                                            '& label.Mui-focused': {
                                                color: 'black',
                                            },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#002F87',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#002F87',
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                width: '100%',
                                                fontSize: '1vw'
                                            }
                                        }} />
                                </FieldRow>
                                <FieldRow>
                                    <FieldLabel>Phone Number*</FieldLabel>
                                    <Input
                                        value={phone}
                                        onChange={event => setPhone(event.target.value)}
                                        style={{ flex: '1 1 auto', marginTop: '2vh' }}
                                        sx={{
                                            '& label.Mui-focused': {
                                                color: 'black',
                                            },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#002F87',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#002F87',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#002F87',
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                width: '100%',
                                                fontSize: '1vw'
                                            }
                                        }} />
                                </FieldRow>
                            </FormDesktopContainer>
                            <FormMobileContainer>
                                <TextField style={TextInputStyle}
                                    value={job}
                                    onChange={event => setJob(event.target.value)}
                                    required
                                    id="outlined-required"
                                    label="Job Title"
                                />
                                <TextField style={TextInputStyle}
                                    value={fName}
                                    onChange={event => setFName(event.target.value)}
                                    required
                                    id="outlined-required"
                                    label="First Name"
                                />
                                <TextField style={TextInputStyle}
                                    value={lName}
                                    onChange={event => setLName(event.target.value)}
                                    required
                                    id="outlined-required"
                                    label="Last Name"
                                />
                                <TextField style={TextInputStyle}
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    required
                                    id="outlined-required"
                                    label="Email"
                                    type="email"
                                />
                                <TextField style={TextInputStyle}
                                    value={phone}
                                    onChange={event => setPhone(event.target.value)}
                                    id="outlined-required"
                                    label="Phone Number"
                                />
                            </FormMobileContainer>
                        </FieldGroup>
                        <FieldGroup height={'40vh'} marginLeft={'2vw'} >
                            <FieldLabel>
                                Message
                            </FieldLabel>
                            <TextAreaStyle
                                value={message}
                                onChange={event => setMessage(event.target.value)}
                            />
                            <ContactUsCondition>
                                By clicking "Submit," you agree to our Privacy Notice on personal data collection, processing, and storage.
                            </ContactUsCondition>
                            <SubmitBtn
                                title={tooltipButtonMessage}
                                disabled={!isValid}
                                onClick={handleSubmit}
                            >Submit</SubmitBtn>
                        </FieldGroup>
                    </MessageRow>
                </MessageForm>
            </ContactDiv>
            <ContactOfficeDiv>
                <ContactIcon src={messageLoactionIcon} />
                <MessageTitle>
                    OUR OFFICES
                </MessageTitle>
                <MessageOfficeForm>
                    <MessageOfficeRow>
                        <FieldGroup height={'100%'} marginRight={'2vw'}>
                            <OfficeDiv>
                                <OfficeTitle>CYPRUS</OfficeTitle>
                                <br />
                                <OfficeText>P.O.TECH - PATHS OF TECHNOLOGY LIMITED</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>Artemidos 5, Artemidos Tower, 8th Floor, 6020 Larnaca</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>Larnaca Business Center, 1 Alpeon Street, 7100 Larnaca</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={phoneSvg} />
                                    <HrefLink href="tel:+35724621527">+357 24 621 527</HrefLink>
                                </OfficeTextWithLogo>
                            </OfficeDiv>
                            <OfficeDiv>
                                <OfficeTitle>FRANCE</OfficeTitle>
                                <br />
                                <OfficeText>POTECH CONSEIL SAS</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeText>THIDESOFT SAS</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>La Canotiere Coworking 6 Av. de la Libération, 92350 Le Plessis-Robinson, Paris</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeText>NIGMA CONSEIL</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>13 Rue Camille Desmoulins, 92130 Issy-les-Moulineaux</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={phoneSvg} />
                                    <HrefLink href="tel:+33686048386">+33 6 86 04 83 86</HrefLink>
                                </OfficeTextWithLogo>
                            </OfficeDiv>
                            <OfficeDiv>
                                <OfficeTitle>UAE</OfficeTitle>
                                <br />
                                <OfficeText>ITELIC Information Technology</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>Office 602 The Exchange Tower, Business Bay, Dubai</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={phoneSvg} />
                                    <HrefLink href="tel:+971502026468">+971 50 202 6468</HrefLink>
                                </OfficeTextWithLogo>
                            </OfficeDiv>
                        </FieldGroup>
                        <FieldGroup height={'100%'} marginLeft={'2vw'}>
                            <OfficeDiv>
                                <OfficeTitle>KINGDOM OF SAUDI ARABIA</OfficeTitle>
                                <br />
                                <OfficeText>POTECH ARABIA COMPANY FOR INFORMATION TECHNOLOGY</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>7570 Al Olaya, Al Olaya District, Riyadh</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={phoneSvg} />
                                    <HrefLink href="tel:+966505366586">+966 50 536 6586</HrefLink>
                                </OfficeTextWithLogo>
                            </OfficeDiv>
                            <OfficeDiv>
                                <OfficeTitle>LEBANON</OfficeTitle>
                                <br />
                                <OfficeText>POTECH CONSULTING SARL</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeText>OBSOFT SARL</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>Berytech Technological Pole, Mar Roukoz, Dekwaneh</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={phoneSvg} />
                                    <HrefLink href="tel:+9614533040">+961 4 533040</HrefLink>&nbsp;&nbsp;Ext. 2209 - 2210
                                </OfficeTextWithLogo>
                            </OfficeDiv>
                            <OfficeDiv>
                                <OfficeTitle>AUSTRALIA</OfficeTitle>
                                <br />
                                <OfficeText>POTECH AUSTRALIA PTY LTD</OfficeText>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={locationSvg} />
                                    <OfficeText>6 Hassall Street, Parramatta, NSW, 2150</OfficeText>
                                </OfficeTextWithLogo>
                                <AdvisoryDivLineItemsMobile />
                                <OfficeTextWithLogo>
                                    <ContactLogo src={phoneSvg} />
                                    <HrefLink href="tel:+61452399005">+614 52 399005</HrefLink>
                                </OfficeTextWithLogo>
                            </OfficeDiv>
                           <HrContact />
                            <AdvisoryDivLineItemsMobile marginTop={'5vh'} />
                            <OfficeTextWithLogo alignItems={'center'}>
                                <ContactLogo src={mailSvg} />
                                <PotechMail href="mailto:info@potech.global">
                                    info@potech.global
                                </PotechMail>
                            </OfficeTextWithLogo>
                        </FieldGroup>
                    </MessageOfficeRow>
                </MessageOfficeForm>
            </ContactOfficeDiv>
        </ContactContainer>
        <FooterDivider />
    </>

}

export default ContactUs;